import "bootstrap/dist/css/bootstrap.css";
import 'material-icons/iconfont/material-icons.css';
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import mdiVue from "mdi-vue/v3";
import * as mdijs from "@mdi/js";
import "./interceptors/axios";

const app = createApp(App).use(router);
app.use(VueAxios, axios);
app.use(mdiVue, { icons: mdijs });
app.provide("axios", app.config.globalProperties.axios);
app.config.globalProperties.$englishPreferred =
  $cookies.isKey("englishPreferred"); // use as $englishPreferred
app.mount("#app");

import "bootstrap/dist/js/bootstrap.js";
