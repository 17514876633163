<template>
  <nav class="bottom-nav">
    <router-link
      class="nav__link"
      to="/"
      v-bind:class="{ 'nav__link--active': $route.path == '/' }"
    >
      <i class="material-icons nav__icon"> home </i>
    </router-link>
    <router-link
      class="nav__link"
      to="/daekningskort"
      v-bind:class="{ 'nav__link--active': $route.path == '/daekningskort' }"
    >
      <i class="material-icons nav__icon"> map </i>
    </router-link>
    <router-link
      class="nav__link"
      to="/statistik"
      v-bind:class="{ 'nav__link--active': $route.path == '/statistik' }"
    >
      <i class="material-icons nav__icon"> analytics </i>
    </router-link>
    <router-link
      class="nav__link"
      to="/indstillinger"
      v-bind:class="{ 'nav__link--active': $route.path == '/indstillinger' }"
    >
      <i class="material-icons nav__icon"> settings </i>
    </router-link>
    <router-link
      class="nav__link"
      to="/vejledning"
      v-bind:class="{ 'nav__link--active': $route.path == '/vejledning' }"
    >
      <i class="material-icons nav__icon"> help </i>
    </router-link>
  </nav>
</template>

<style scoped>
.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  font-family: sans-serif;
  font-size: 13px;
  color: #ffff;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
}

.nav__link:hover {
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(45deg, #1f7aaf, #51af30);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(45deg, #1f7aaf, #51af30);
}

@supports (padding: max(0px)) {
  .bottom-nav {
    padding-left: max(12px, env(safe-area-inset-left));
    padding-right: max(12px, env(safe-area-inset-right));
  }
}

.bottom-nav {
  background-color: #1f7aaf;
  padding-bottom: 12px;
}

.nav__link--active {
  color: #51af30;
}

.nav__icon {
  font-size: 3.5em;
}

a.nav__link:hover {
  color: rgb(255, 255, 255) !important;
}
</style>
