const DB_NAME = "SpontanDB";
const DB_VERSION = 1;
let DB;

export default {
  async getDb() {
    return new Promise((resolve, reject) => {
      if (DB) {
        return resolve(DB);
      }

      // no support
      if (!("indexedDB" in window)) reject("not supported");

      let indexedDB =
        window.indexedDB ||
        window.mozIndexedDB ||
        window.webkitIndexedDB ||
        window.msIndexedDB;

      let request = indexedDB.open(DB_NAME, DB_VERSION);

      request.onerror = (e) => {
        console.log("Error opening db", e);
        reject("Error");
      };

      request.onsuccess = (e) => {
        DB = e.target.result;
        resolve(DB);
      };

      request.onblocked = (e) => {
        console.log("Opening of db was blocked", e);
        reject("Blocked");
      };

      request.onupgradeneeded = (e) => {
        console.log("onupgradeneeded");
        let db = e.target.result;
        console.log(e.oldVersion);

        if (e.oldVersion < 1) {
          console.log("v1");
          let collectionsObjectStore = db.createObjectStore("collections", {
            autoIncrement: true,
            keyPath: "id",
          });
          collectionsObjectStore.createIndex("date", "date", { unique: false });
          collectionsObjectStore.createIndex("startTime", "startTime", {
            unique: false,
          });
          db.createObjectStore("observations", {
            autoIncrement: true,
            keyPath: "id",
          });
          db.createObjectStore("sitesChecksum", {
            autoIncrement: true,
            keyPath: "id",
          });
          db.createObjectStore("speciesChecksum", {
            autoIncrement: true,
            keyPath: "id",
          });
          db.createObjectStore("ages", { autoIncrement: true, keyPath: "id" });
          db.createObjectStore("directions", {
            autoIncrement: true,
            keyPath: "id",
          });
          db.createObjectStore("plumages", {
            autoIncrement: true,
            keyPath: "id",
          });
          db.createObjectStore("primaryBehaviours", {
            autoIncrement: true,
            keyPath: "id",
          });
          db.createObjectStore("secondaryBehaviours", {
            autoIncrement: true,
            keyPath: "id",
          });
          db.createObjectStore("sites", { autoIncrement: true, keyPath: "i" });
          db.createObjectStore("species", {
            autoIncrement: true,
            keyPath: "id",
          });
          db.createObjectStore("recentSpecies", {
            autoIncrement: true,
            keyPath: "id",
          });
          db.createObjectStore("recentBehaviours", {
            autoIncrement: true,
            keyPath: "id",
          });
          db.createObjectStore("atlasSquares", {
            autoIncrement: true,
            keyPath: "properties.kvadratnr",
          });
          db.createObjectStore("specieRankings", {
            autoIncrement: true,
            keyPath: "ranking",
          });
          db.createObjectStore("generalAtlasSquareStatistics", {
            autoIncrement: true,
            keyPath: "id",
          });
          db.createObjectStore("atlasSquarePoints", {
            autoIncrement: true,
            keyPath: "kvadratnr",
          });
          let countPointsObjectStore = db.createObjectStore("countPoints", {
            autoIncrement: true,
            keyPath: "id",
          });
          countPointsObjectStore.createIndex("datetime", "datetime", {
            unique: false,
          });
          countPointsObjectStore.createIndex("kvadratnr", "kvadratnr", {
            unique: false,
          });
          countPointsObjectStore.createIndex("collectionId", "collectionId", {
            unique: false,
          });
        }
        if (e.oldVersion < 2) {
          
        }
      };
    });
  },

  async clearObjectStoreByName(name) {
    let db = await this.getDb();

    const transaction = db.transaction([name], "readwrite");
    transaction.oncomplete = () => {
      console.log("cleared " + name);
    };
    const objectStore = transaction.objectStore(name);
    const objectStoreRequest = objectStore.clear();

    objectStoreRequest.onsuccess = () => {
      console.log("successfully cleared objectstore for " + name);
    };
  },

  async getCountByObjectStore(objectStoreTitle) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction([objectStoreTitle], "readonly");
      trans.oncomplete = () => {
        resolve(count);
      };

      let store = trans.objectStore(objectStoreTitle);
      let count = store.count();
    });
  },
};
