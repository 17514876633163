import axios from "axios";
//import Router from "../router";
import idb from "../idb/idb.js";

const isProduction = process.env.NODE_ENV === 'production';

const baseURL = isProduction
  ? 'https://api.dofbasen.dk/api/v1.0/' // URL for build mode
  : 'http://localhost:8080/api/v1.0/'; // URL for serve mode
axios.defaults.baseURL = baseURL;
axios.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

function getLocalAccessToken() {
  const accessToken = JSON.parse(window.localStorage.getItem("access_token"));
  return accessToken;
}
function getLocalRefreshToken() {
  const refreshToken = JSON.parse(window.localStorage.getItem("refresh_token"));
  return refreshToken;
}

function refreshToken() {
  return axios.get("/token/refresh");
}

axios.interceptors.request.use(
  (config) => {
    const token =
      config.url == "/token/refresh"
        ? getLocalRefreshToken()
        : getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    /*
        Any status code from range of 2xx
        Do something with response data
        */
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    /*
        Any status codes outside range of 2xx
        Do something with response error
        */
    if (error.response) {
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await refreshToken();
          window.localStorage.setItem(
            "access_token",
            JSON.stringify(rs.data.access_token)
          );
          return axios(originalConfig);
        } catch (_error) {
          if (
            _error.response &&
            _error.response.data &&
            _error.response.status
          ) {
            // in case of 403 status remove access and refresh token and redirect user to login page
            if (_error.response.status === 403) {
              // Clear user content in indexedDB
              await idb.clearObjectStoreByName("observations");
              await idb.clearObjectStoreByName("countPoints");
              await idb.clearObjectStoreByName("collections");
              await idb.clearObjectStoreByName("generalAtlasSquareStatistics");
              await idb.clearObjectStoreByName("sitesChecksum");
              await idb.clearObjectStoreByName("speciesChecksum");

              window.localStorage.removeItem("access_token");
              window.localStorage.removeItem("refresh_token");
              window.location.reload();
            }
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      }
      if (error.response.status === 401) {
        console.log("401 error");
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);
