<template>
  <div :class="{ 'main-div': $route.path != '/login' }">
    <router-view />
    <span v-if="isNavbarRoute">
      <NavFooter />
    </span>
  </div>
</template>

<script>
import NavFooter from "./components/navbars/NavFooter.vue";
import { inject } from "vue";
import VueCookies from "vue-cookies";

export default {
  name: "App",
  data() {
    return {
      navfooterRoutes: [
        "Home",
        "CompletedCount",
        "CoverageMap",
        "Settings",
        "Statistics",
        "Instructions",
      ],
    };
  },
  components: {
    NavFooter,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    isNavbarRoute() {
      if (this.navfooterRoutes.includes(this.currentRouteName)) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
/* prevent-pull-to-refresh.css */
html,
body {
  overscroll-behavior-y: contain;
}

.map-card {
  top: 20px;
  background-color: #1f7aaf;
  color: white;
  width: 20rem;
}

#map {
  height: calc(100% - 55px);
  width: 100%;
  top: 55px;
  left: 0;
  position: absolute;
  z-index: -300;
}

#mapActions {
  top: 600px;
  z-index: -250;
}

.map-button {
  z-index: 0 !important;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 67px;
  display: flex;
  overflow-x: auto;
}

.upper-bottom-nav {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 142px;
}

.list-group-item,
.card,
.box-shadow {
  box-shadow: 0 1px 10px rgb(0 0 0 / 0.2);
}

.nav__link:hover {
  background-color: #eeeeee;
}

.bottom-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  font-family: sans-serif;
  font-size: 13px;
  color: #ffff;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.is-selected {
  background: #51af30 !important;
}

.force-to-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  padding-bottom: 30px;
}

.button-material-icon {
  position: relative;
  /* Adjust these values accordingly */
  top: 5px;
}

#install-app-btn-container {
  display: none;
}

#pwa-browser-compability-container {
  display: none;
}

#ios-webkit-install-instructions-container {
  display: none;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #1f7aaf;
}

.input-div {
  min-height: 500px;
}

.main-div {
  margin: 0 0 55px 0;
  user-select: none;
}

#color-switch-btn {
  background: linear-gradient(to right, #1f7aaf, #51af30) !important;
}

.gradient-text {
  background-color: #f3ec78;
  background-image: linear-gradient(to right, #1f7aaf, #51af30);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.btn-primary:hover {
  background-color: #19648f !important;
  color: white !important;
  text-decoration: none;
}

.alert-primary {
  background-color: #51af30;
  color: white;
}

.btn-default,
.btn-default:active,
.btn-default:visited {
  background-color: #51af30;
  color: white;
  text-decoration: none;
}

.btn-default:hover {
  background-color: #489b29;
  color: white;
  text-decoration: none;
}

.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary:focus {
  border-color: #1f7aaf;
  color: #1f7aaf;
  text-decoration: none;
}

.active {
  background-color: #51af30 !important;
}

.btn-outline-primary:hover {
  background: #19648f;
  color: white !important;
  text-decoration: none;
}

.btn-outline-primary:visited {
  color: #1f7aaf;
}

.divider {
  width: 5px;
  height: auto;
  display: inline-block;
}

/* Rules for sizing the icon. */
.material-icons.md-14 {
  font-size: 14px;
}
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-20 {
  font-size: 20px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-28 {
  font-size: 28px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}

.clickable-icon:hover {
  cursor: pointer;
}
</style>
