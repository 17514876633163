<template>
  <div
    class="modal fade"
    id="confirm-modal"
    tabindex="-1"
    role="dialog"
    ref="modal"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Vil du forlade igangværende tælling?</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="cancel"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Er du sikker på at du vil forlade tællingen? Dine observationer gemmes
          ikke før din indtastning er afsluttet.
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="cancel"
            data-dismiss="modal"
          >
            Annuller
          </button>
          <button type="button" class="btn btn-primary" @click="confirm">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  data() {
    return {
      isConfirmationVisible: true,
      confirmModal: null,
      confirmationMessage: "Are you sure you want to proceed?",
    };
  },
  mounted() {
    this.confirmModal = new Modal(document.getElementById("confirm-modal"), {});
    this.confirmModal.show();
  },
  methods: {
    showConfirmation() {
      this.isConfirmationVisible = true;
    },
    confirm() {
      // Perform the action when confirmed
      console.log("Confirmed!");
      // Remove the element or component from the DOM
      this.confirmModal.hide();
      const confirmComponentElement = document.getElementById("prompt-component");
      confirmComponentElement.remove();
      this.$router.push("/");
    },
    cancel() {
      // Handle cancellation
      console.log("Cancelled.");
      this.confirmModal.hide();
      const confirmComponentElement = document.getElementById("prompt-component");
      confirmComponentElement.remove();
    },
  },
};
</script>