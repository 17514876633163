import { createRouter, createWebHashHistory } from "vue-router";
import { createApp } from "vue";
import RouterConfirmModal from "../components/RouterConfirmModal.vue";
//import router from "./router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/nedtaeller",
    name: "Countdown",
    component: () => import("../views/CountdownPage.vue"),
  },
  {
    path: "/taelling/:id",
    name: "SeeCount",
    component: () => import("../views/SeeCount.vue"),
  },
  {
    path: "/taelling/:id/turdata",
    name: "EditCollectionData",
    component: () => import("../views/EditCollectionData.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/daekningskort",
    name: "CoverageMap",
    component: () => import("../views/CoverageMap.vue"),
  },
  {
    path: "/indstillinger",
    name: "Settings",
    component: () => import("../views/Settings.vue"),
  },
  {
    path: "/statistik",
    name: "Statistics",
    component: () => import("../views/Statistics.vue"),
  },
  {
    path: "/vejledning",
    name: "Instructions",
    component: () => import("../views/Instructions.vue"),
  },
  {
    path: "/taelling/ny",
    name: "NewCount",
    component: () => import("../views/NewCount.vue"),
  },
  {
    path: "/taelling/indtast",
    name: "AddCompletedCount",
    component: () => import("../views/AddCompletedCount.vue"),
  },
  {
    path: "/taelling/mine",
    name: "MyCounts",
    component: () => import("../views/MyCounts.vue"),
  },
  {
    path: "/",
    name: "CompletedCount",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/statistik/projekt",
    name: "ProjectStatistics",
    component: () => import("../views/ProjectStatistics.vue"),
  },
  {
    path: "/statistik/rang/taellinger/total",
    name: "TotalCountsRanking",
    component: () => import("../components/rankings/TotalCountsRanking.vue"),
  },
  {
    path: "/statistik/rang/taellinger/aar",
    name: "TotalCountsCurrentYearRanking",
    component: () =>
      import("../components/rankings/TotalCountsCurrentYearRanking.vue"),
  },
  {
    path: "/statistik/rang/taellinger/30",
    name: "TotalCounts30DaysRanking",
    component: () =>
      import("../components/rankings/TotalCounts30DaysRanking.vue"),
  },
  {
    path: "/statistik/rang/point/total",
    name: "TotalPointsRanking",
    component: () => import("../components/rankings/TotalPointsRanking.vue"),
  },
  {
    path: "/statistik/rang/point/aar",
    name: "TotalPointsCurrentYearRanking",
    component: () =>
      import("../components/rankings/TotalPointsCurrentYearRanking.vue"),
  },
  {
    path: "/statistik/rang/point/30",
    name: "TotalPoints30DaysRanking",
    component: () =>
      import("../components/rankings/TotalPoints30DaysRanking.vue"),
  },
  // used as embed
  {
    path: "/embed/daekningskort",
    name: "CoverageMapEmbed",
    component: () => import("../views/CoverageMapEmbed.vue"),
    meta: {
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

let count = 0;

router.beforeEach((to, from, next) => {
  // Make sure user is logged in or direct user to login page
  var isAuthenticated = JSON.parse(window.localStorage.getItem("access_token"));

  if (to.meta && to.meta.requiresAuth === false) {
    next();
    return;
  } else if (to.name !== "Login" && !isAuthenticated) next({ name: "Login" });
  else if (to.name == "Login" && isAuthenticated) next({ name: "Home" });

  if (from.name == "NewCount" || from.name == "AddCompletedCount") {
    if (to.name == "Home") {
      if (count < 1) {
        const confirmComponentApp = createApp(RouterConfirmModal).use(router);

        const confirmComponentElement = document.createElement("div");
        confirmComponentElement.id = "prompt-component";
        document.body.appendChild(confirmComponentElement);
        confirmComponentApp.mount(confirmComponentElement);

        count++;
        return false;
      } else {
        count = 0;
      }
    }
  }
  next();
});

export default router;
